import dynamic from "next/dynamic";
import Link from "next/link";
import { CaretRight } from "phosphor-react";
import { ReactElement } from "react";

// Components
const ScrollLink = dynamic(() =>
  import("react-scroll").then((mod) => mod.Link)
);

type ButtonApperances = "default" | "wide";
type ButtonTints = "default" | "brand" | "dark";
type ButtonProps = React.ComponentPropsWithoutRef<"button"> & {
  label: string;
  tint?: ButtonTints;
  appearence?: ButtonApperances;
  disabled?: boolean;
  icon?: ReactElement;
  to?: string;
  href?: string;
};

function Button({ appearence = "default" }: ButtonProps) {
  switch (appearence) {
    case "wide":
      return <Wide {...arguments[0]} />;
    default:
      return <Default {...arguments[0]} />;
  }
}

function Default({
  label,
  icon,
  className,
  tint,
  href,
  disabled,
  ...props
}: ButtonProps) {
  var style: {
    container: string;
  };
  switch (tint) {
    case "dark":
      style = {
        container: `flex items-center border px-3 py-2 rounded text font-medium select-none bg-gray-900 border-gray-900 shadow text-gray-100 active:bg-gray-800 active:border-gray-800 focus-visible:`,
      };
      break;
    case "brand":
      style = {
        container: `flex items-center border px-3 py-2 rounded text font-medium select-none bg-brand-500 border-brand-500 shadow text-white active:bg-brand-800 active:border-brand-800`,
      };
      break;
    default:
      style = {
        container: `flex items-center border px-3 py-2 rounded text font-medium select-none bg-gray-500 border-gray-500 shadow text-white active:bg-gray-400 active:border-gray-400`,
      };
      break;
  }
  const button = (
    <button
      {...props}
      disabled={disabled}
      className={`${className} ${style.container}`}
    >
      <span className="sr-only">{label}</span>
      <span className={`flex items-center space-x-2.5`}>
        {icon && <span className={"text-2xl"}>{icon}</span>}
        <span className={`mt-0.5`}>{label}</span>
      </span>
    </button>
  );

  return <>{href ? <Link href={href}>{button}</Link> : <>{button}</>}</>;
}

function Wide({
  label,
  icon,
  tint,
  className,
  to,
  href,
  ...props
}: ButtonProps) {
  var style = {
    parent: `${className} bg-white rounded-lg overflow-hidden cursor-pointer`,
    container: "",
  };
  switch (tint) {
    case "brand":
      style = {
        ...style,
        container: `bg-brand-500 text-white active:bg-brand-600`,
      };
      break;
    default:
      style = {
        ...style,
        container: `bg-gray-100 text-gray-900 active:text-brand-600`,
      };
      break;
  }
  const element = (
    <div
      className={`${style.container} relative w-full px-4 py-3.5 flex place-content-between`}
    >
      <span className={`flex items-center space-x-5`}>
        <span className={`text-2xl`}>{icon}</span>
        <span className={`tracking-medium text-lg pt-px font-medium`}>
          {label}
        </span>
      </span>
      <span className={`font-bold tracking-medium text-xl flex items-center`}>
        <CaretRight weight={"regular"} />
      </span>
    </div>
  );

  return (
    <>
      {to && (
        <ScrollLink
          // @ts-ignore
          to={to}
          duration={150}
          smooth={true}
          offset={-50}
          className={style.parent}
          {...{ ...props }}
        >
          {element}
        </ScrollLink>
      )}

      {href && (
        <Link href={href}>
          <a className={style.parent}>{element}</a>
        </Link>
      )}
    </>
  );
}

export default Button;
